<template>
  <svg
    class="map country india"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 202.2 200.3"
  >
    <g class="hover">
      <path
        d="M182.8,97l.6,4.6-2.1-1,1.1,5.2-2.1-3.3-.8-3.3L178,96.1l-2.8-3.7-5.2-.3.9,2.7-1.2,3.5L167.1,97l-.6,1.2-1.7-.7-2.2-.6L161,91.6l-2.6-4.8.3-3.9L155,81.2l.9-2.3,3-2.4-4.6-3.4,1.2-4.4,4.9,2.8,2.7.3,1.2,4.5,5.4.9,5.1-.1,3.4,1.1-1.6,5.4-2.4.4L173,87.6l3.6,3.4.3-4.2h1.5L182.8,97Z"
        style="
          stroke: #010101;
          stroke-linecap: round;
          stroke-linejoin: round;
          stroke-width: 0.20000000298023224px;
        "
      />
      <path
        d="M171,60.2l2.6,2.1.5,3.9-4.5.2-4.7-.4-3.2,1-5.5-2.5-.4-1.2,2.6-4.8,2.6-1.6,4.3,1.4,2.9.2,2.8,1.7Z"
        style="
          stroke: #010101;
          stroke-linecap: round;
          stroke-linejoin: round;
          stroke-width: 0.20000000298023224px;
        "
      />
      <path
        :class="`hover india ${isActive('India')}`"
        data-loc="95,70"
        @click="quickViewShop('india')"
        d="M109.8,44.7l-2.8,3-.9,6,5.8,2.4,5.8,3.1,7.8,3.6,7.7.9,3.8,3.2,4.3.6,6.9,1.5,4.6-.1.1-2.5-1.5-4.1-.2-2.7,3.1-1.4,1.5,5.1.4,1.2,5.5,2.5,3.2-1,4.7.4,4.5-.2-.5-3.9L171,60.2l4.2-.8,3.9-4.8,5.4-4,4.9,1.5,3.2-2.7,3.6,4L195,56.1l6.1,1,1,2.4-1.7,1.2,1.4,3.9-4.2-1.1-6.2,4.4.9,3.7-2,5.4.3,3.1L189,85.4l-4.6-1.5.9,6.7-1,2.2,1,2.7L182.8,97l-4.4-10.2h-1.5l-.3,4.2L173,87.6l1.2-3.6,2.4-.4,1.6-5.4-3.4-1.1-5.1.1-5.4-.9-1.2-4.5-2.7-.3-4.9-2.8-1.2,4.4,4.6,3.4-3,2.4-.9,2.3,3.7,1.7-.3,3.9,2.6,4.8,1.6,5.3-.5,2.4-3.8-.1-6.6,1.3.9,4.8-2.4,3.8-7.5,4.4-5.3,7.5-3.8,4.1-5,4.2.3,2.9-2.6,1.6-4.8,2.3-2.6.3-1.2,4.9,1.9,8.4.7,5.3-1.9,6.1.7,10.9-2.9.3-2.3,4.9,1.9,2.2-5.1,1.8-1.7,4.3-2.2,1.9-5.6-6-3.1-9-2.5-6.5-2.2-3L90,156.7l-2-8-1.4-4-5.9-8.8-3.5-12.5-2.6-8.2-.8-7.8-1.7-6-7.7,3.9-4-.8-8.1-7.8,2.4-2.3-1.9-2.5-7.1-5.5,3.2-4.3H61l-1.8-5.5-3.5-3.2-1.4-5-4-2.8,4.9-6.8,6.5.5,4.5-6.7,2.2-6.5,3.9-6.5-1-4.6,3.8-3.7L70,28.2l-2.9-4.4-3.3-5.6,2-2.8L74.3,17,80,16l3.8-5.4,7.7,7.6.8,5.2,3,3.3.6,3.3-4.1-.9L95,36.2l6.2,4,8.6,4.5Z"
        style="
          stroke: #010101;
          stroke-linecap: round;
          stroke-linejoin: round;
          stroke-width: 0.20000000298023224px;
        "
      />
      <path
        d="M128.1,198.7l-4.8,1.5-2.9-5.1-1.4-9.2,2-10.4,4.1,3.5,2.8,4.5,3.1,6.7-.6,6.7-2.3,1.8Z"
        style="
          stroke: #010101;
          stroke-linecap: round;
          stroke-linejoin: round;
          stroke-width: 0.20000000298023224px;
        "
      />
      <path
        d="M151.2,59.6l.2,2.7,1.5,4.1-.1,2.5-4.6.1-6.9-1.5-4.3-.6-3.8-3.2-7.7-.9-7.8-3.6-5.8-3.1-5.8-2.4.9-6,2.8-3,1.9-1.5,4.8,2,6.4,4.2,3.3.9,2.5,3.1,4.5,1.2,5,2.9,6.5,1.4,6.5.7Z"
        style="
          stroke: #010101;
          stroke-linecap: round;
          stroke-linejoin: round;
          stroke-width: 0.20000000298023224px;
        "
      />
      <path
        d="M83.8,10.6,80,16l-5.7,1-8.5-1.6-2,2.8,3.3,5.6L70,28.2l5.1,3.1L71.3,35l1,4.6-3.9,6.5-2.2,6.5-4.5,6.7-6.5-.5-4.9,6.8,4,2.8,1.4,5,3.5,3.2L61,82.1H48.9l-3.2,4.3-4.2-1.6-2.2-4.6-4.9-4.9-10,1.2-9,.1-7.6.9,1.1-7.4,7.5-3.4-.9-2.9-2.7-1.1-1-5.6L6.1,54.3,3.3,50.4.1,47l9.6,3.3,5.3-1,3.4.8.9-1.4,3.9.6,6.6-2.7L29,41.2l2.3-3.7h4.1l.2-1.7,4-.9,2.1.6,1.8-1.8-1.1-3.9L43.8,26l3.1-1.7-3.1-4.2,5.2.2.9-2.3-.8-2.5,2-2.7L49.7,9.6,47.8,6.9l2.4-2.8,5.3-1.3L61.4,2,63.8.9,66.7.1l4.7,3,2.9,5,9.5,2.5Z"
        style="
          stroke: #010101;
          stroke-linecap: round;
          stroke-linejoin: round;
          stroke-width: 0.20000000298023224px;
        "
      />
    </g>
    <g
      class="pin"
      :transform="`translate(${targetLoc[0]} ${targetLoc[1]}) scale(0.8)`"
    >
      <path
        fill="#000"
        d="M17.54,3.52A9.9,9.9,0,0,0,12,.21,9.91,9.91,0,0,0,.22,7.65,9.48,9.48,0,0,0,0,10.18c-.16,5.18,3.92,10,7.7,15,.93,1.15,2.23,2.42,2.29,2.57l2.08-2.46C16.82,19.08,23.74,10.05,17.54,3.52Zm-2.86,7.79A4.88,4.88,0,0,1,9,15.16l-.12,0a5,5,0,0,1-3.75-5.9,4.87,4.87,0,0,1,5.78-3.82A5,5,0,0,1,14.68,11.31Z"
      ></path>
      <path
        fill="#fff"
        d="M11.55,2.93A7.37,7.37,0,0,0,2.82,8.77a7.6,7.6,0,0,0,5.67,9l.18,0a7.37,7.37,0,0,0,8.55-5.87A7.59,7.59,0,0,0,11.55,2.93Zm3.13,8.38A4.88,4.88,0,0,1,9,15.16l-.12,0a5,5,0,0,1-3.75-5.9,4.87,4.87,0,0,1,5.78-3.82A5,5,0,0,1,14.68,11.31Z"
      ></path>
      <text x="25" y="20" class="small">{{ country }}</text>
    </g>
    <text
      x="88"
      y="90"
      :class="`small ${isActive('India')}`"
      style="font-size: 15px"
    >
      India
    </text>
  </svg>
</template>
<script>
export default {
  name: "India",
  data() {
    return {
      targetLoc: [0, 0],
    };
  },
  props: {
    country: String,
  },
  mounted() {
    const target = document.querySelector(".map .active");
    this.targetLoc = [
      target.dataset.loc.split(",")[0],
      target.dataset.loc.split(",")[1],
    ];
    this.log("Map:#901", "Country", this.country, target);
  },
  methods: {
    isActive(query) {
      return query === this.country ? "active" : "";
    },
    quickViewShop(selection) {
      if (selection !== "shop") {
        this.$store.commit("saveFilterInStore", [selection]);
        this.$store.commit("saveActiveFilters", [1, 0, 0, 0]);
      }
      window.location = "#/user/shop";
    },
  },
};
</script>
